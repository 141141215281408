import React, { useCallback, useMemo, useState, useRef, useEffect } from 'react';
import PT from 'prop-types';
import block from 'bem-cn';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import SVGInline from 'react-svg-inline';

import { actions } from 'features/tree';
import SVG from 'components/SVG';

import arrowSvg from '../img/down-arrow.svg';
import banSvg from '../img/ban.svg';

import './TreeRow.scss';

const b = block('tree-row');

const TreeRow = ({ row, nesting, lvl, isLastBranch, isTotalLastBranch }) => {
  const dispatch = useDispatch();

  const { username, role, userId, players, balance, isBanned, subusersCount } = row;

  const [isOpened, setOpened] = useState(row.childrens?.length ? false : null);

  const locale = useSelector(state => state.locale.locale, shallowEqual);

  const handleLoadUsersList = useCallback(() => {
    dispatch(actions.getUsersByUserId({ id: userId, nesting }));
  }, [nesting, dispatch, userId]);

  const onClick = useCallback(() => {
    if (subusersCount) {
      if (isOpened === null) {
        setOpened(true);
        handleLoadUsersList();
      } else {
        setOpened(prevState => !prevState);
      }
    }
  }, [isOpened, subusersCount, handleLoadUsersList]);

  const childrens = useMemo(
    () =>
      row.childrens?.map((t, index, array) => (
        <TreeRow
          key={t.userId}
          nesting={[...nesting, t.userId]}
          row={t}
          lvl={lvl + 1}
          isLastBranch={index === array.length - 1}
          isTotalLastBranch={isTotalLastBranch && index === array.length - 1}
        />
      )) || [],
    [row, nesting],
  );

  const onBanClick = useCallback(
    e => {
      // dispatch(
      //   usersActions.changeBanState(
      //     userId,
      //     !isBanned,
      //     nesting.length > 1
      //       ? handleLoadUsersList
      //       : () => {
      //           dispatch(actions.getUsersByUserId());
      //         }
      //   )
      // );
      e.stopPropagation();
      dispatch(actions.banUser({ id: userId, nesting, value: !isBanned }));
    },
    [dispatch, userId, isBanned, nesting],
  );

  return (
    <div className={b({ banned: isBanned })}>
      <div className={b('parent', { withoutSubusers: !subusersCount })} onClick={onClick}>
        {subusersCount ? (
          <SVG svgProps={{ svg: arrowSvg }} className={b('arrow', { opened: isOpened, disabled: !subusersCount })} />
        ) : null}
        <div className={b('data1')}>
          <span className={b('data1-role')}>{locale.role[role]}&emsp;{locale.id}: {userId}</span>
          <span className={b('data1-username')}>{username}</span>

          <div className={b('data1-players')}>
            <span className={b('data1-players-title')}>{locale.players}:</span>
            <span className={b('data1-players-value')}>{players}</span>
          </div>
        </div>

        <div className={b('data22')}>
          <div className={b('subusers')}>
            <div className={b('subusers-title')}>{locale.subusers}:</div>
            <div className={b('subusers-value')}>{subusersCount}</div>
          </div>
        </div>

        <div className={b('data3')}>
          <div
            className={b('ban')}
            onClick={onBanClick}
            style={{ border: isBanned ? '1px solid #C4C6C9' : '1px solid #EA2326' }}>
            {!isBanned && <SVG svgProps={{ svg: banSvg }} className={b('ban-icon')} />}
            <span className={b('ban-text')}>{isBanned ? locale.unban : locale.ban}</span>
          </div>

          <div className={b('balance1')}>
            <div className={b('balance1-title')}>{locale.balance}:</div>
            <div className={b('balance1-value')}>{balance}</div>
          </div>
        </div>
      </div>
      {isOpened && (
        <div className={b('childrens')}>
          <div className={b('ladder')}>
            <div className={b('ladder-vertical')} />
            <div className={b('ladder-horizontal2')} />
          </div>
          {childrens}
        </div>
      )}
      {!isOpened && <div className={b('ladder-horizontal1')} />}
    </div>
  );
};

TreeRow.propTypes = {};

export default TreeRow;
